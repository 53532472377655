/* src/pages/Home.css */
.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-container {
  text-align: center;
  padding: 2rem;
  color: var(--text-color);
}

.typewriter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  color: var(--text-color);
}

.typewriter1 {
  position: absolute;
  margin-bottom: 5rem;
}

.typewriter2 {
  position: absolute;
  margin-top: 5rem;
}

.highlight {
  font-weight: bold;
  color: var(--highlight-color);
}

.spacer-section {
  height: 20vh;
}

.spacer-section-small {
  height: 15vh;
}

.section {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.section.visible {
  opacity: 1;
}

.section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.section p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.status-container {
  background-color: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  max-width: 1000px;
  margin: auto;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
}

.status-container.animated {
  opacity: 1;
  transform: translateY(0);
}

.status-container:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 48px rgba(0, 0, 0, 0.2);
}

.status-container h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--highlight-color);
  text-align: center;
}

.status-cards {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.status-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1.5rem;
  flex: 1 1 calc(33% - 1rem);
  min-width: 250px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.status-card.animate-in {
  opacity: 1;
  transform: translateY(0);
}

.status-card:nth-child(1) { transition-delay: 0.1s; }
.status-card:nth-child(2) { transition-delay: 0.2s; }
.status-card:nth-child(3) { transition-delay: 0.3s; }
.status-card.other-projects { transition-delay: 0.4s; }

.status-card.other-projects {
  flex-basis: 100%;
  margin-top: 1.5rem;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.status-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.status-card h3 {
  font-size: 1.3rem;
  color: var(--highlight-color);
  margin-bottom: 1rem;
}

.status-card p {
  font-size: 1rem;
  line-height: 1.5;
}

.status-card a {
  color: var(--highlight-color);
  text-decoration: none;
  font-weight: bold;
}

.status-card a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .typewriter-container {
    font-size: 1.8rem;
  }

  .status-cards {
    flex-direction: column;
  }

  .status-card {
    margin-bottom: 1rem;
    min-width: auto;
  }

  .status-container h2 {
    font-size: 2rem;
  }

  .status-card h3 {
    font-size: 1.2rem;
  }

  .status-card p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .typewriter-container {
    font-size: 1.5rem;
  }
}