.about-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 1500px;
  overflow: hidden;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--highlight-color);
}

.resume-container {
  width: 300px;
  height: 300px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
}

.resume-container:hover {
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
  transform: translateY(-10px);
}

.document-icon {
  font-size: 180px;
  color: var(--highlight-color);
  transition: all 0.3s ease;
  transform-style: preserve-3d;
  transform: translateZ(50px);
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.2));
}

.resume-container:hover .document-icon {
  transform: translateZ(75px) scale(1.1);
}

.resume-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
  transform: translateZ(60px);
}

.resume-container:hover .resume-overlay {
  opacity: 1;
}

.resume-overlay p {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  transform: translateZ(75px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.github-link {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-size: 2rem;
  color: var(--text-color);
  transition: color 0.3s ease, transform 0.3s ease;
}

.github-link:hover {
  color: var(--highlight-color);
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .resume-container {
    width: 250px;
    height: 250px;
  }
  
  .document-icon {
    font-size: 150px;
  }

  .github-link {
    bottom: 10px;
    right: 10px;
    font-size: 1.5rem;
  }
}