/* src/components/FloatingNavbar.css */
.navbar {
    position: fixed;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(240, 240, 240, 0.5);
    padding: 0.5rem 1.5rem;
    border-radius: 9999px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    gap: 2rem;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  }
  
  .navbar-hover {
    background-color: rgba(240, 240, 240, 1);
    transform: translate(-50%, -5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .navbar.dark-mode {
    background-color: rgba(18, 18, 18, 0.5);
  }
  
  .navbar.dark-mode.navbar-hover {
    background-color: rgba(18, 18, 18, 1);
    transform: translate(-50%, -5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .menu {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  
  .menu-item-container {
    position: relative;
  }
  
  .menu-item {
    cursor: pointer;
  }
  
  .menu-item p {
    transition: color 0.3s;
  }
  
  .menu-item:hover p {
    opacity: 0.5;
  }
  
  .submenu-container {
    position: absolute;
    top: 3rem;
    left: 0;
    right: 0;
    justify-content: center;
    display: none;
  }
  
  .menu-item-container:hover .submenu-container,
  .submenu-container:hover {
    display: flex;
  }
  
  .submenu {
    display: flex;
    gap: 1rem;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .submenu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #000;
  }
  
  .submenu-item:hover {
    text-decoration: underline;
  }
  
  .submenu-img {
    width: 100px;
    height: auto;
    margin-bottom: 0.5rem;
  }
  
  .dark-mode-toggle {
    cursor: pointer;
    background: none;
    border: none;
    color: inherit;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }

  .dark-mode-toggle:hover {
    opacity: 0.5;
  }
  
  .dark-mode .submenu {
    background-color: #121212;
    color: #ffffff;
  }
  
  .dark-mode .submenu-item {
    color: #ffffff;
  }
  
  .dark-mode .submenu-item:hover {
    opacity: 0.5;
  }
  
  .overlay {
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    pointer-events: none; /* Prevent interaction when not active */
  }
  
  .overlay.active {
    opacity: 1;
    pointer-events: all; /* Allow interaction when active */
  }
  

  @media (max-width: 768px) {
    .navbar {
      top: 0.5rem;
      padding: 0.3rem 1rem;
      max-width: 95%;
      gap: 1rem;
    }
  
    .menu {
      gap: 1rem;
    }
  
    .menu-item p {
      font-size: 0.9rem;
    }
  
    .dark-mode-toggle {
      font-size: 1.2rem;
    }
  }