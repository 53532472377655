.projects-container {
  height: calc(100vh - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1500px;
  overflow: hidden;
  position: relative;
  padding-bottom: 50px;
}

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.project-card {
  position: absolute;
  width: 300px;
  height: 400px; /*450*/
  left: calc(50% - 170px);
  top: calc(50% - 180px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  text-align: center;
  transition: all 0.5s;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  backface-visibility: hidden;
  transform-origin: center center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-card.active {
  transform: rotateY(0deg) translateZ(600px) !important;
}

.project-card.unavailable {
  cursor: default;
}

.project-card h2 {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--highlight-color);
  margin-bottom: 5px;
}

.project-card .category {
  font-size: 0.8em;
  background: var(--highlight-color);
  color: var(--background-color);
  padding: 3px 8px;
  border-radius: 12px;
  display: inline-block;
  margin-bottom: 10px;
}

.project-card .project-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-radius: 50px;
  margin-bottom: 10px;
  position: relative;
  mask-image: radial-gradient(circle, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  -webkit-mask-image: radial-gradient(circle, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.project-card p {
  font-size: 0.9em;
  margin-bottom: 10px;
}

.tech-stack {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tech-stack span {
  background: var(--highlight-color);
  color: var(--background-color);
  padding: 3px 8px;
  border-radius: 12px;
  margin: 3px;
  font-size: 0.7em;
}

.loc, .coming-soon {
  font-size: 0.7em;
  opacity: 0.8;
}

.coming-soon {
  font-weight: bold;
  color: var(--highlight-color);
  text-shadow: 0 0 10px var(--highlight-color, rgba(255, 255, 255, 0.8)), /* Horizontal and vertical shadow */
               0 0 20px var(--highlight-color, rgba(255, 255, 255, 0.6)), /* Larger blur for a glow effect */
               0 0 30px var(--highlight-color, rgba(255, 255, 255, 0.4)); /* Even larger blur */
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: var(--highlight-color);
  color: var(--background-color);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5em;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;
}

.nav-button:hover {
  background-color: var(--text-color);
}

.nav-button.prev {
  left: 20px;
}

.nav-button.next {
  right: 20px;
}



.carousel-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.indicator-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--text-color);
  opacity: 0.5;
  margin: 0 5px;
  transition: all 0.3s ease;
}

.indicator-dot.active {
  width: 12px;
  height: 12px;
  opacity: 1;
  background-color: var(--highlight-color);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.indicator-dot.active {
  animation: pulse 1.5s infinite;
}




@media (max-width: 1024px) {
  .project-card {
    width: 250px;
    height: 350px;
    left: calc(50% - 150px);
    top: calc(50% - 175px);
  }

  .project-card h2 {
    font-size: 1.3em;
  }

  .project-card .category {
    font-size: 0.7em;
  }

  .project-card .project-image {
    height: 120px;
  }

  .project-card p {
    font-size: 0.8em;
  }

  .tech-stack span {
    font-size: 0.6em;
  }

  .loc, .coming-soon {
    font-size: 0.6em;
  }
}

@media (max-width: 768px) {
  .projects-container {
    height: calc(100vh - 100px);
    padding-bottom: 100px;
  }

  .project-card {
    width: 220px;
    height: 300px;
    left: calc(50% - 130px);
    top: calc(50% - 150px);
  }

  .project-card h2 {
    font-size: 1.2em;
  }

  .project-card .category {
    font-size: 0.6em;
  }

  .project-card .project-image {
    height: 100px;
  }

  .project-card p {
    font-size: 0.7em;
  }

  .tech-stack span {
    font-size: 0.5em;
    padding: 2px 6px;
  }

  .loc, .coming-soon {
    font-size: 0.5em;
  }

  .nav-button {
    width: 30px;
    height: 30px;
    font-size: 1.2em;
  }

  .nav-button.prev {
    left: 10px;
  }

  .nav-button.next {
    right: 10px;
  }

  .carousel-indicator {
    bottom: 10px;
  }

  .indicator-dot {
    width: 6px;
    height: 6px;
    margin: 0 3px;
  }

  .indicator-dot.active {
    width: 10px;
    height: 10px;
  }
}

@media (max-width: 480px) {
  .projects-container {
    height: calc(100vh - 80px);
    padding-bottom: 80px;
  }

  .project-card {
    width: 160px;
    height: 250px;
    left: calc(50% - 96px);
    top: calc(50% - 100px);
    padding: 15px;
  }

  .project-card h2 {
    font-size: 1em;
  }

  .project-card .category {
    font-size: 0.5em;
  }

  .project-card .project-image {
    height: 80px;
  }

  .project-card p {
    font-size: 0.6em;
  }

  .tech-stack span {
    font-size: 0.4em;
    padding: 2px 4px;
    margin: 2px;
  }

  .loc, .coming-soon {
    font-size: 0.4em;
  }

  .nav-button {
    width: 25px;
    height: 25px;
    font-size: 1em;
  }

  .nav-button.prev {
    left: 5px;
  }

  .nav-button.next {
    right: 5px;
  }

  .carousel-indicator {
    bottom: 5px;
  }

  .indicator-dot {
    width: 4px;
    height: 4px;
    margin: 0 2px;
  }

  .indicator-dot.active {
    width: 8px;
    height: 8px;
  }
}