.erp-container {
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    color: var(--text-color);
    padding-top: 80px;
  }

  .view-project-btn {
    background-color: var(--highlight-color);
    color: var(--background-color);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .view-project-btn:hover {
    background-color: var(--text-color);
  }
  
  .erp-container h1 {
    font-size: 2.5rem;
    color: var(--highlight-color);
    margin-bottom: 2rem;
  }
  
  .erp-content {
    max-width: 800px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    padding: 2rem;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .erp-content h2 {
    font-size: 1.8rem;
    color: var(--highlight-color);
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .erp-content p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .erp-content ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .erp-content li {
    margin-bottom: 1rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .erp-content li::before {
    content: '•';
    color: var(--highlight-color);
    position: absolute;
    left: 0;
  }
  
  .erp-content strong {
    color: var(--highlight-color);
  }
  
  @media (max-width: 768px) {
    .erp-container {
      padding: 1rem;
    }
  
    .erp-content {
      padding: 1.5rem;
    }
  
    .erp-container h1 {
      font-size: 2rem;
    }
  
    .erp-content h2 {
      font-size: 1.5rem;
    }
  }

  @media (max-width: 480px) {
    .erp-container {
      padding-top: 4rem;
    }
  }