/* src/App.css */
:root {
  --text-color: #000000;
  --highlight-color: #007BFF;
  --background-color: #f0f0f0;
  --background-light: url('../public/images/background_light.jpg');
  --background-dark: url('../public/images/background_dark.jpg');
}

body {
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s, color 0.3s, background-image 0.3s;
  margin: 0;
  padding: 0;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  color: var(--text-color);
  background-image: var(--background-light);
}

body.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --highlight-color: #FF5733;
  background-image: var(--background-dark);
}

body.light-mode {
  --background-color: #f0f0f0;
  --text-color: #000000;
  --highlight-color: #007BFF;
  background-image: var(--background-light);
}

a {
  text-decoration: none;
  color: inherit;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container > *:not(nav) {
  flex-grow: 1;
}