.contact-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: var(--highlight-color);
  }
  
  .contact-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  
  .icon-link {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .icon-link:hover {
    transform: scale(1.1);
  }
  
  .contact-icon {
    font-size: 3rem;
    color: var(--highlight-color);
    transition: color 0.3s ease;
  }

  .contact-icon:hover {
    color: var(--text-color);
  }
  
  .email-display {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    backdrop-filter: blur(10px);
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .email-display p {
    font-size: 1rem;
    color: var(--text-color);
    margin: 0;
  }
  
  .copy-button {
    background-color: var(--highlight-color);
    color: var(--background-color);
    border: none;
    border-radius: 3px;
    padding: 0.3rem 0.6rem;
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    transition: background-color 0.3s ease;
  }
  
  .copy-button:hover {
    background-color: var(--text-color);
  }
  
  .copy-button svg {
    font-size: 1rem;
  }